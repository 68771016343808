import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import '../../styles/Navbar.css';


const SurveyNavLink = ({ NavLinkInfo, last }) => {

    return (
        <NavLink to={NavLinkInfo.to} className="nav-link py-0">
            <div className='d-flex flex-row align-items-stretch'>
                <div className='align-self-stretch d-flex flex-column me-2 circle-survey' >
                    <div className={`vertical-connector bg-light ${(NavLinkInfo.index === 1) ? "opacity-0" : ""}`} />
                    <div className={`align-content-center rounded-circle bg-light circle-navlink ${(NavLinkInfo.status === 1) && " circle-navlink-completed"}`} style={{ minWidth: "45px", minHeight: "45px" }}>
                        {(NavLinkInfo.status == 1) ?
                            <p className='m-0 text-center tick-navlink'>✓</p>
                            :
                            <p className='m-0 text-center index-navlink'>{NavLinkInfo.index}</p>
                        }
                    </div>
                    <div className={`vertical-connector bg-light ${last ? "opacity-0" : ""}`} />
                </div>
                <div className='align-self-center contained-text py-3'>
                    <p className='mb-1 topic mobile-hide'>{NavLinkInfo.category}</p>
                    <p className={`fs-10 m-0 status mobile-hide ${NavLinkInfo.status == 1 ? "text-success" : "text-danger"}`} style={{ fontSize: "0.8rem" }}>{NavLinkInfo.statusLabel}</p>
                </div>
            </div>
        </NavLink>
    );
};

export default SurveyNavLink;
