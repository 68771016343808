import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const LineGraph = ({ xData, yData, width = 500, height = 300 }) => {
    const svgRef = useRef();

    useEffect(() => {
        const svg = d3.select(svgRef.current);
        svg.selectAll("*").remove(); // Clear previous chart before rendering new one

        const margin = { top: 20, right: 30, bottom: 30, left: 40 };
        const innerWidth = width - margin.left - margin.right;
        const innerHeight = height - margin.top - margin.bottom;

        // Remove any null values from the yData and remove the corresponding xData values
        const filteredYData = yData.filter(d => d !== null);
        const filteredXData = xData.filter((d, i) => yData[i] !== null);

        // Scales
        const x = d3.scaleLinear()
            .domain(d3.extent(filteredXData)) // Use extent to dynamically fit the x-axis
            .range([0, innerWidth]);

        const y = d3.scaleLinear()
            .domain([-2.5, 2.5]) // Automatically fit y-axis based on max value
            .range([innerHeight, 0]);

        // Line generator
        const line = d3.line()
            .x((d, i) => x(filteredXData[i])) // Using the xData for x-axis values
            .y((d, i) => y(filteredYData[i])) // Using the yData for y-axis values
            .curve(d3.curveCardinal); // Smooth the line for aesthetics

        // Append group for margins
        const g = svg.append("g")
            .attr("transform", `translate(${margin.left},${margin.top})`);

        // X-axis
        g.append("g")
            .attr("transform", `translate(0, ${y(0)})`)
            .call(d3.axisBottom(x).tickFormat(d3.format("d"))); // Format to display years if xData contains years

        // Y-axis
        g.append("g")
            .call(d3.axisLeft(y));

        // Line path
        g.append("path")
            .datum(filteredYData)
            .attr("fill", "none")
            .attr("stroke", "#6572EB")
            .attr("stroke-width", 2.5)
            .attr("d", line);

        // Data points (optional)
        g.selectAll(".dot")
            .data(filteredYData)
            .enter()
            .append("circle")
            .attr("class", "dot")
            .attr("cx", (d, i) => x(filteredXData[i]))
            .attr("cy", (d, i) => y(filteredYData[i]))
            .attr("r", 4)
            .attr("fill", "#6572EB")
            .attr("stroke", "white")
            .attr("stroke-width", 1.5);

    }, [xData, yData, width, height]);

    return (
        <svg ref={svgRef} width={width} height={height} />
    );
};

export default LineGraph;
