import React from 'react';
import '../../styles/KPI.css'; // Custom CSS for modern styling

const KPI = ({ title, value, unit = "", description = "", icon = null }) => {
    return (
        <div className="kpi-container bg-white w-100 h-100">
            <div className="kpi-header text-break">
                {icon && <div className="kpi-icon">{icon}</div>}
                <h3 className="kpi-title">{title}</h3>
            </div>
            <div className="kpi-value">
                {value}<span className="kpi-unit"> {unit}</span>
            </div>
            <div className="kpi-body">
                {description && <p className="kpi-description">{description}</p>}
            </div>
        </div>
    );
};

export default KPI;
