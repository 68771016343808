import React, { useState, useEffect } from 'react';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

// CSS
import '../../styles/YesNoToggle.css';

const YesNoToggle = ({ question, value, onChange, is_child }) => {
    const [selected, setSelected] = useState(value);

    // Handle toggle switch change
    const handleToggleChange = (newValue) => {
        setSelected(newValue);
        onChange(newValue, question.id); // Send the value back to the parent component
    };

    useEffect(() => {
        setSelected(value);
    }, [value]);


    return (
        <div className={`yes-no-toggle w-100 select-mobile ${!is_child ? ' mt-5 pb-3' : 'mt-3 py-1'}`}>
            <div className="col-4">
                <label className="form-label fw-bold text-start">{question.question}</label>
                <p className=' text-secondary'>This is a sample description for this question and should be added. This is a sample description for this question and should be added. This is a sample description for this question and should be added.</p>
            </div>
            <div className="toggle-wrapper col-8 d-flex align-items-center px-3">
                <input
                    type="checkbox"
                    className="toggle-switch"
                    checked={selected === 'yes'}
                    onChange={() => handleToggleChange(selected === 'yes' ? 'no' : 'yes')}
                    id={`yes-no-toggle-${question.id}`}
                />
                <label
                    className="toggle-label"
                    htmlFor={`yes-no-toggle-${question.id}`}
                    data-on="No"
                    data-off="Yes"
                ></label>
                <span className={`icon-${selected === 'yes' ? 'yes' : 'no'} ms-2`}>
                    {selected === 'yes' ? <FaCheckCircle className="text-success" /> : <FaTimesCircle className="text-danger" />}
                </span>
            </div>
        </div>
    );
};

export default YesNoToggle;
