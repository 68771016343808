// src/components/PieChart.js
import React from 'react';
import { Pie } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
} from 'chart.js';
import { getColor } from '../../common/utils';

ChartJS.register(
    ArcElement,
    Tooltip,
    Legend
);



const PieChart = ({ companyData }) => {
    const sortedOverview = [...companyData].sort((a, b) => b.score - a.score);
    const categories = sortedOverview.map(item => item.category);
    const riskValues = sortedOverview.map(item => item.score);

    const data = {
        labels: categories,
        datasets: [
            {
                label: 'Risk Potential',
                data: riskValues,
                backgroundColor: riskValues.map(risk => getColor(risk)),
                borderColor: 'rgba(0,0,0,1)',
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: 'top',
            },
            tooltip: {
                callbacks: {
                    label: (context) => {
                        return `${context.label}: ${context.raw}`;
                    },
                },
            },
        },
    };

    return (
        <div>
            <h2>Risk Distribution by Category</h2>
            <p>This pie chart shows the proportion of risk distributed across different categories, providing a visual representation of how risk is spread within the company.</p>
            <hr className="bg-body-secondary mb-6 mt-4" />
            <Pie data={data} options={options} />
        </div>
    );
};

export default PieChart;
