import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import * as topojson from 'topojson-client';

const FocusedCountryMap = ({ countryName, width = 600, height = 400 }) => {
    const svgRef = useRef();

    useEffect(() => {
        const svg = d3.select(svgRef.current);
        svg.selectAll("*").remove(); // Clear the previous map

        const projection = d3.geoMercator().scale(1).translate([0, 0]);
        const path = d3.geoPath().projection(projection);

        // Load world GeoJSON data
        d3.json("https://raw.githubusercontent.com/holtzy/D3-graph-gallery/master/DATA/world.geojson").then((data) => {
            // Filter the GeoJSON data for the selected country
            const countryData = data.features.filter(
                (d) => d.properties.name.toLowerCase() === countryName.toLowerCase()
            );

            if (countryData.length === 0) {
                console.error(`Country ${countryName} not found`);
                return;
            }

            // Create a bounding box for the selected country
            const bounds = path.bounds(countryData[0]);
            const dx = bounds[1][0] - bounds[0][0];
            const dy = bounds[1][1] - bounds[0][1];
            const x = (bounds[0][0] + bounds[1][0]) / 2;
            const y = (bounds[0][1] + bounds[1][1]) / 2;
            const scale = 0.8 / Math.max(dx / width, dy / height);
            const translate = [width / 2 - scale * x, height / 2 - scale * y];

            // Update the projection
            projection
                .scale(scale)
                .translate(translate);

            // Draw the country on the map
            svg.append("g")
                .selectAll("path")
                .data(countryData)
                .enter()
                .append("path")
                .attr("d", path)
                .attr("fill", "#40739e")
                .attr("stroke", "#fff")
                .attr("stroke-width", 1.5);

        }).catch((error) => {
            console.error("Error loading the map:", error);
        });
    }, [countryName, width, height]);

    return <svg ref={svgRef} width={width} height={height} />;
};

export default FocusedCountryMap;
