// src/components/RadarChart.js
import React from 'react';
import { Radar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { threatMatrix } from '../../data/data';

ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const RadarChart = ({ companyData }) => {
    const categories = companyData.map(item => item.category);
    const riskValues = companyData.map(item => item.score);

    const data = {
        labels: categories,
        datasets: [
            {
                label: 'Risk Potential',
                data: riskValues,
                backgroundColor: 'rgba(75,192,192,0.4)',
                borderColor: 'rgba(75,192,192,1)',
                borderWidth: 1,
            },
        ],
    };

    const options = {
        scales: {
            r: {
                beginAtZero: true,
                min: 0,
                max: 100,
            },
        },
    };

    return (
        <div>
            <h2>Comprehensive Risk Analysis</h2>
            <p>The radar chart offers a comprehensive analysis of the risk landscape by plotting each risk category on an axis, making it easy to see which areas have the highest risk scores.</p>
            <hr className="bg-body-secondary mb-6 mt-4" />
            <Radar data={data} options={options} />
        </div>
    );
};

export default RadarChart;
