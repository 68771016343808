import React from 'react';

// Styles
import '../../styles/Select.css';


const Select = ({ question, options, value, onChange }) => {
    return (
        <div className="select-container">
            <label className="mb-4 fw-bold">{question.question}</label>
            <select className="dropdown" value={value} onChange={(e) => onChange(e.target.value, question.id)}>
                <option value="" >Select an option</option>
                {options.map((option, index) => (
                    <option key={index} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default Select;