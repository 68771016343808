import React from 'react';
import { Routes, Route } from 'react-router-dom';

// Pages
import SurveyPage from '../../pages/SurveyPage';


const SurveyRoutes = (props) => {
    return (
        <Routes>
            <Route path="/" element={<>This is a placeholder</>} />
            <Route path="topic1" element={<SurveyPage {...props} topicId="topic1" />} />
            <Route path="topic2" element={<SurveyPage {...props} topicId="topic2" />} />
            <Route path="topic3" element={<SurveyPage {...props} topicId="topic3" />} />
            <Route path="topic4" element={<SurveyPage {...props} topicId="topic4" />} />
            <Route path="topic5" element={<SurveyPage {...props} topicId="topic5" />} />
            <Route path="topic6" element={<SurveyPage {...props} topicId="topic6" />} />
            <Route path="topic7" element={<SurveyPage {...props} topicId="topic7" />} />
            <Route path="topic8" element={<SurveyPage {...props} topicId="topic8" />} />
            <Route path="topic9" element={<SurveyPage {...props} topicId="topic9" />} />
            <Route path="topic10" element={<SurveyPage {...props} topicId="topic10" />} />
            <Route path="topic11" element={<SurveyPage {...props} topicId="topic11" />} />
        </Routes>
    );
};

export default SurveyRoutes;
