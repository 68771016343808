// src/pages/Dashboard.js
import React, { useState } from 'react';

// Pages
import WelcomePage from './WelcomePage';

// Graphs
import Heatmap from '../components/Graphs/HeatMap';
import RadarChart from '../components/Graphs/RadarChart';
import BarGraph from '../components/Graphs/BarGraph';
import PieChart from '../components/Graphs/PieChart';
import PredictiveAnalytics from '../components/Graphs/PredictiveAnalytics';
import RiskKPIs from '../components/Graphs/RiskKPIs';
import Benchmarking from '../components/Graphs/Benchmarking';
import DemographicsPieChart from '../components/Graphs/DemographicsPieChart';
import LineChart from '../components/Graphs/LineChart';
import EmploymentDisputesChart from '../components/Graphs/EmploymentDisputeChart';
import Donut from '../components/Graphs/Donut';

// Chart configs
import LineChart_standard from '../chart_configs/LineChart_standard.json';

// styles
import '../styles/Dashboard.css'; // Create a CSS file for styling

// Assests
import demo_dashboard from '../assests/demo_dashboard.svg';

const Dashboard = ({ user, dataExists, dashbaordData, trendData, industryBenchmarks, surveyQuestions }) => {
    const [responses, setResponses] = useState([]);

    const handleSubmitResponses = (newResponses) => {
        setResponses([...responses, newResponses]);
    };

    return (
        <div className="dashboard">
            <img src={demo_dashboard} alt="React Logo" draggable={false} />
        </div>
    );

    const employmentData = {
        xValues: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        yValues: [
            {
                label: 'Hires',
                data: [30, 40, 35, 50, 55, 60, 45, 50, 65, 70, 60, 50],
                color: '#395C6B'
            },
            {
                label: 'Resignations',
                data: [15, 20, 18, 25, 22, 28, 24, 26, 30, 35, 33, 25],
                color: '#80A4ED'
            },
            {
                label: 'Terminations',
                data: [5, 8, 6, 10, 12, 15, 10, 12, 14, 16, 12, 10],
                color: '#BCD3F2'
            }
        ]
    };

    const employmentDisputeData = {
        categories: ['Dismissals', 'Labor Lawsuits', 'Other Disputes'],
        disputes: [50, 30, 20], // Example: [dismissals, labor lawsuits, other disputes]
    };

    const data = [
        { value: 129, label: 'Male', description: 'New employees hired' },
        { value: 105, label: 'Female', description: 'Employees resigned' },
        { value: 20, label: 'Non-Binary', description: 'Employees terminated' },
    ];

    return (
        <div className="dashboard pb-5 letter-spacing" style={{ backgroundColor: "#46556A" }}>
            {dataExists ? <>
                <div className='container mt-5'>
                    <h2 className='text-primary'>Human Resources</h2>
                    <h2 className='text-white fw-light'>Key Data Summary</h2>
                </div>
                <div className='container mt-3 rounded bg-white p-5'>

                    <div className='row mb-5'>
                        <div className='col-12 pe-5'>
                            <div className='col-10 pe-5'>
                                <h3 className='text-primary'>Employment Changes</h3>
                                <p className='text-muted'>The following chart displays the number of hires, resignations, and terminations per month. This data can be used to identify trends in employee turnover and inform retention strategies.</p>
                                <LineChart data={employmentData} chart_config={LineChart_standard} />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-8 pe-5'>
                            <Donut data={data} />
                        </div>
                    </div>
                    <div className='row d-flex mb-5'>
                        <div className='col-4 pe-5'>
                            <h3 className='text-primary'>Demographics</h3>
                            <p className='text-muted'>The following chart displays the distribution of employees within the company. This data can be used to identify any disparities in representation and inform diversity and inclusion initiatives.</p>
                            <DemographicsPieChart data={[10, 20, 30]} />
                        </div>
                        <div className='col-8 ps-5'>
                            <h3 className='text-primary'>Employment Changes</h3>
                            <p className='text-muted'>The following chart displays the number of hires, resignations, and terminations per month. This data can be used to identify trends in employee turnover and inform retention strategies.</p>
                            <LineChart data={employmentData} chart_config={LineChart_standard} />
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-8">

                            <h3 className="text-primary">Employment Disputes and Legal Actions</h3>
                            <p className="text-muted">The following chart displays the number of dismissals, labor lawsuits, and other disputes within the company. This data can be used to identify areas of legal risk and inform compliance efforts.</p>
                        </div>
                        <div className="col-8">
                            <EmploymentDisputesChart data={employmentDisputeData} />
                        </div>
                    </div>
                </div>
            </>
                :
                <WelcomePage />}
        </div >
    );

    return (
        <div className="dashboard">
            {dataExists ? <>
                <div className='dashboard-header'>
                    <h2>Company Risk Dashboard</h2>
                </div>
                <div className='dashboard-summary'>
                    <div className='dashboard-summary-item'>
                        <h2>Key Insights</h2>
                        <ul>
                            <li><b>Compliance and Corruption: </b>Compliance violations and corruption allegations represent the highest risks, indicating a need for stringent internal controls and transparency measures.</li>
                            <li><b>International Exposure: </b>International service providers and general disinformation attacks are prominent risk areas, suggesting a focus on enhancing global cybersecurity measures.</li>
                            <li><b>Product Reputation: </b>Maintaining the integrity and reputation of our products is crucial, given the moderate to high risk scores associated with disinformation about product safety and quality.</li>
                        </ul>
                    </div>
                    <div className='dashboard-summary-item'>
                        <RiskKPIs totalRiskScore={dashbaordData.totalRiskScore} highRiskCategories={dashbaordData.highRiskCategories} />
                    </div>
                    <div className='dashboard-summary-item'>
                        <h2>Recommendations</h2>
                        <ul>
                            <li><b>Strengthen Compliance Programs: </b>Implement robust compliance and ethics programs to mitigate risks associated with management violations and corruption allegations.</li>
                            <li><b>Enhance Cybersecurity: </b>Focus on cybersecurity measures, especially for international operations and service providers, to counteract disinformation attacks.</li>
                            <li><b>Monitor and Mitigate Product Disinformation: </b>Actively monitor and respond to disinformation related to product safety, quality, and efficacy to protect brand reputation.</li>
                        </ul>
                    </div>
                </div>
                <hr className="mb-0 mt-0" />

                <div className="dashboard-grid">
                    <div className="bg-white border dashboard-grid-item">
                        <RadarChart companyData={dashbaordData.categoryScores} />
                    </div>
                    <div className="bg-white border dashboard-grid-item">
                        <PieChart companyData={dashbaordData.categoryScores} />
                    </div>
                    <div className="bg-white border dashboard-grid-item">
                        <BarGraph companyData={dashbaordData.categoryScores} />
                    </div>
                    <div className="bg-white border dashboard-grid-item">
                        <Heatmap companyData={dashbaordData.categoryScores} countries={dashbaordData.countries} />
                    </div>
                    <div className="bg-white border dashboard-grid-item">
                        <Benchmarking companyData={dashbaordData.categoryScores} industryBenchmarks={industryBenchmarks} />
                    </div>
                    <div className="bg-white border dashboard-grid-item">
                        <PredictiveAnalytics trendData={trendData} />
                    </div>
                </div>
            </>
                :
                <WelcomePage />}
        </div>
    );
};

export default Dashboard;
