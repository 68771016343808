// src/components/BarGraph.js
import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { getColor } from '../../common/utils';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const BarGraph = ({ companyData }) => {
    const sortedOverview = [...companyData].sort((a, b) => b.score - a.score);

    const categories = sortedOverview.map(item => item.category);
    const riskValues = sortedOverview.map(item => item.score);

    const data = {
        labels: categories,
        datasets: [
            {
                label: 'Risk Potential',
                data: riskValues,
                backgroundColor: riskValues.map(risk => getColor(risk)),
                borderColor: 'rgba(0,0,0,1)',
                borderWidth: 1,
            },
        ],
    };

    const options = {
        scales: {
            y: {
                beginAtZero: true,
                min: 0,
                max: 100,
            },
        },
    };

    return (
        <div>
            <h2>Risk Scores by Category</h2>
            <p>This bar graph illustrates the risk scores for each category, enabling a clear comparison of risk levels across different aspects of the company.</p>
            <hr className="bg-body-secondary mb-6 mt-4" />
            <Bar data={data} options={options} />
        </div>
    );
};

export default BarGraph;
