// src/components/Navbar.js
import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import '../../styles/Navbar.css';

// auth
import { useAuth } from '../../auth/AuthProvider';
// Components
import SurveyNavLink from './SurveyNavLink';
import { index } from 'd3';

const Navbar = ({ companyData, fillSampleData, surveyQuestions }) => {
    const { currentUser } = useAuth();

    const [isCompanyDataOpen, setIsCompanyDataOpen] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    useEffect(() => {
        if (window.location.href.includes('/company-data')) {
            setIsCompanyDataOpen(true);
        } else {
            setIsCompanyDataOpen(false);
        }
    }, [window.location.href]);

    const handle = (e) => {
        setIsCompanyDataOpen(false);
    }

    if (!currentUser) {
        return (<div className="navbar-custom navbar-vertical bg-white  border-end">
            <nav>

            </nav>
            <div className="agency-tag">
                <p>Demo created by<br /><b>faltstein Solutions</b></p>
            </div>
        </div>)
    }

    return (
        <div className="navbar-custom navbar-vertical bg-white border-end">
            <nav >
                <ul className='navbar-nav mr-auto gap-0 pt-2'>
                    <li className='nav-item m-0 active' onClick={handle}>
                        <NavLink
                            to="/dashboard"
                            className="nav-link fs-5"
                            end
                        >
                            Report
                        </NavLink>
                    </li>
                    <li className='nav-item m-0 active' onClick={handle}>
                        <NavLink
                            to="/analytics"
                            className="nav-link fs-5"
                            end
                        >
                            Analytics
                        </NavLink>
                    </li>
                    <div onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave} >
                        <li className='nav-item m-0'>
                            <NavLink
                                to="/company-data"
                                className="nav-link fs-5"
                            >
                                Company Data
                            </NavLink>
                        </li>
                        <div className={`nested-links  ${(isCompanyDataOpen || isHovered) ? 'expanded' : 'collapsed'}`}>
                            {/* We create all nav links for the topics in the companydata */
                                companyData.map((topic, index) => (
                                    <li className='nav-item m-0' key={topic.id}>
                                        <SurveyNavLink NavLinkInfo={{
                                            category: surveyQuestions.find(question => question.id === topic.id).category,
                                            statusLabel: topic.statusLabel,
                                            status: topic.status,
                                            index: index + 1,
                                            to: `/company-data/${topic.id}`,
                                        }}
                                            last={index === companyData.length - 1} />
                                    </li>
                                ))
                            }
                        </div>
                    </div>
                    <li className='nav-item m-0'>
                        <button onClick={fillSampleData} className="btn btn-outline-success">Fill Sample Data</button>
                    </li>
                </ul>
            </nav>
            <div className="agency-tag">
                <p>Demo created by<br /><b>faltstein Solutions</b></p>
            </div>
        </div>
    );
};

export default Navbar;
