// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { get } from "firebase/database";

// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration

const firebaseConfig = {
    apiKey: "AIzaSyCvUEMfLA5PGlKWrhktVV2IsXDOdVO33M8",
    authDomain: "risk-assessment-webapp.firebaseapp.com",
    projectId: "risk-assessment-webapp",
    storageBucket: "risk-assessment-webapp.appspot.com",
    messagingSenderId: "807121112739",
    appId: "1:807121112739:web:d9becbd9d32057a2877916"
};


// Initialize Firebase

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);