// src/components/Heatmap.js
import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { threatMatrix } from '../../data/data';
import { getColor } from '../../common/utils';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const Heatmap = ({ companyData, countries }) => {
    const heatmapData = companyData.map((item, index) => {
        const sortedOverview = countries.map(() => {
            if (item.score <= 0) {
                return 0;
            } else {
                return item.score - (Math.random() * 20)
            }
        })
            .sort((a, b) => b - a);
        return {
            label: item.category,
            data: sortedOverview, // Simulated data for demo purposes
            backgroundColor: countries.map(() => getColor(item.score)),
            borderColor: 'rgba(0,0,0,1)',
            borderWidth: 1,
        };
    });

    const data = {
        labels: countries,
        datasets: heatmapData,
    };

    const options = {
        scales: {
            x: { stacked: true },
            y: { stacked: true },
        },
    };

    return (
        <div>
            <h2>Regional Risk Exposure</h2>
            <p>The heatmap displays the risk levels across various regions, highlighting areas with higher risk exposure. This helps to identify geographical regions that require more attention.</p>
            <hr className="bg-body-secondary mb-6 mt-4" />
            <Bar data={data} options={options} />
        </div>
    );
};

export default Heatmap;
