// src/components/TopBar.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Container, Nav, NavDropdown, Button, Collapse } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

import '../../styles/TopBar.css';

import logo from '../../assests/logo.png';
// auth
import { useAuth } from '../../auth/AuthProvider';


const TopBar = () => {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const { currentUser, logout } = useAuth();

  const handleLogout = () => {
    logout();
  };

  if (!currentUser) {
    return (<nav className="navbar fixed-top navbar-expand-lg bg-white px-3 top-bar shadow-none border-bottom">
      <a className="navbar-brand" href="/dashboard">
        <img src={logo} width="30" height="30" className="d-inline-block align-top" alt="" />
        TruthGuard
      </a>
      <div className="navbar-nav ms-auto">
      </div>
    </nav>)
  }

  return (
    <Navbar expand="lg" className="bg-white p-0 top-bar shadow-none border-bottom">
      <Container fluid>
        <Navbar.Brand href="/">
          <img src={logo} width="30" height="30" className="d-inline-block align-top" alt="" />
          TruthGuard
        </Navbar.Brand>
        <Navbar.Toggle className="navbar-toggle" aria-controls="basic-navbar-nav"
          onClick={() => setNavbarOpen(!navbarOpen)}>
          <FontAwesomeIcon className="navbar-toggler-icon" icon={faBars} />
        </Navbar.Toggle>
        <Collapse in={navbarOpen} navbar>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Link className="nav-link" to="/faq">FAQ</Link>
              <Link className="nav-link" to="/contact">Contact</Link>
              <Button className="btn btn-primary text-white" variant="primary" onClick={handleLogout}>Logout</Button>
            </Nav>
          </Navbar.Collapse>
        </Collapse>
      </Container>
    </Navbar>
  );
};

export default TopBar;
