// src/pages/RiskAssessment.js
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { count } from 'd3';

//style
import '../styles/SurveyPage.css';


// Components
import Select from '../components/SurveyQuestions/Select';
import WorldMap from '../components/SurveyQuestions/WorldMap';
import RangeSlider from '../components/SurveyQuestions/RangeSlider';
import SelectCheckBox from '../components/SurveyQuestions/SelectCheckBox';
import NestedInput from '../components/SurveyQuestions/NestedInput';
import IntegerInput from '../components/SurveyQuestions/IntegerInput';
import YesNoToggle from '../components/SurveyQuestions/YesNoToggle';

const SurveyPage = ({ companyData, setCompanyData, ComputeDashboardData, topicId, surveyQuestions }) => {
  const navigate = useNavigate();

  // Find the questions for this topic
  const topic = surveyQuestions.filter(topic => topic.id === topicId)[0];

  // Find the answer in the company data that corresponds to this topic
  let answers = companyData.filter(topic => topic.id === topicId)[0];

  // This function handles a change in the questions
  const handleChange = (value, id) => {
    // Update the company data using functional state update to ensure we have the latest state
    setCompanyData(prevCompanyData => {
      return prevCompanyData.map(topic => {
        if (topic.id === topicId) {
          const newQuestions = topic.questions.map(answer => {
            if (answer.id === id) {
              console.log({ ...answer, answer: value });
              return { ...answer, answer: value };
            }
            return answer;
          });

          const answeredCount = newQuestions.reduce((count, answer) => {
            const type = surveyQuestions.filter(topic => topic.id === topicId)[0].questions.filter(question => question.id === answer.id)[0].type;
            if (type === 'world_map' && answer.answer.length > 0) {
              return count + 1;
            } else if (type === 'select_range' && answer.answer !== "") {
              return count + 1;
            } else if (type === 'select_checkbox' && answer.answer.length > 0) {
              return count + 1;
            } else if (type === 'integer' && answer.answer !== "") {
              return count + 1;
            } else if (type === 'yes_no' && answer.answer !== "") {
              return count + 1;
            }
            return count;
          }, 0);

          return {
            ...topic,
            questions: newQuestions,
            status: answeredCount === newQuestions.length ? 1 : 0,
            statusLabel: answeredCount === newQuestions.length ? "Completed" : "Missing",
          };
        }
        return topic;
      });
    });

    return value;
  }

  const handleRecomputeDashboardData = () => {
    ComputeDashboardData(companyData)
  };

  const handleNextTopic = () => {
    const currentTopicIndex = companyData.findIndex(topic => topic.id === topicId);
    if (currentTopicIndex < companyData.length - 1) {
      navigate(`/company-data/${companyData[currentTopicIndex + 1].id}`);
    }
  };

  const handlePreviousTopic = () => {
    const currentTopicIndex = companyData.findIndex(topic => topic.id === topicId);
    if (currentTopicIndex > 0) {
      navigate(`/company-data/${companyData[currentTopicIndex - 1].id}`);
    }
  };

  const handleQuestionType = (question, parent_id = null, isChild = false) => {
    // Here we build the question components based on their type
    // additionally, since we have nested questions, we need to cheeck for those
    // and then in a recursive manner add them to our child questions
    if (question.parent_id && question.parent_id !== parent_id) {
      return null;
    }

    let question_component = null;
    if (question.type === 'select') {
      question_component = <Select key={question.id} question={question} options={question.options} value={answers.questions.filter(item => item.id === question.id)[0].answer} onChange={handleChange} />;
    } else if (question.type === 'world_map') {
      question_component = <WorldMap key={question.id} dynamic={true} question={question} value={answers.questions.filter(item => item.id === question.id)[0].answer} onChange={handleChange} />;
    } else if (question.type === 'select_range') {
      question_component = <RangeSlider key={question.id} question={question} options={question.options} value={answers.questions.filter(item => item.id === question.id)[0].answer} onChange={handleChange} />;
    } else if (question.type === 'select_checkbox') {
      question_component = <SelectCheckBox
        key={question.id}
        question={question}
        options={question.options}
        value={answers.questions.filter(item => item.id === question.id)[0].answer}
        onChange={handleChange}
        is_child={isChild} />;
    } else if (question.type === 'integer') {
      question_component = <IntegerInput
        key={question.id}
        question={question}
        value={answers.questions.filter(item => item.id === question.id)[0].answer}
        onChange={handleChange}
        is_child={isChild} />;
    } else if (question.type === 'yes_no') {
      question_component = <YesNoToggle
        key={question.id}
        question={question}
        value={answers.questions.filter(item => item.id === question.id)[0].answer}
        onChange={handleChange}
        is_child={isChild} />;
    } else {
      question_component = <p key={question.id}>Question type not supported</p>;
    }

    // iterate through all questions in the topic and check if they are a child question of this one
    // if they are, add them to the childQuestions array
    let childQuestions = [];
    for (let i = 0; i < topic.questions.length; i++) {
      if (topic.questions[i].parent_id === question.id) {
        let childQuestion = handleQuestionType(topic.questions[i], question.id, true);
        childQuestions.push(childQuestion);
      }
    }
    if (childQuestions.length > 0) {
      return <NestedInput parentQuestion={question_component} childQuestions={childQuestions} />;
    }

    // add a border to the question component if its not a nested question
    // we only do this for parent questions
    question_component = <div className={`w-100 ${!isChild ? 'border-bottom mt-5 pb-3' : ''}`}>{question_component}</div>;
    return question_component;
  }

  return (

    <div className="container p-5 mt-1">
      <div className="row">
        <div className="col-md-8">
          <h1 className='mb-4'>{topic.category}</h1>
          <p>{topic.description}</p>
        </div>
      </div>
      <div className='survey-mobile'>
        <div className="container mt-2 pb-3">
          {topic.questions.map((question, index) => (
            handleQuestionType(question)
          ))}
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-md-8" />
        <div className="col-md-4 d-flex justify-content-end align-items-center">
          {companyData.findIndex(topic => topic.id === topicId) > 0 && (
            <button className='btn btn-outline-primary text-primary me-3' onClick={handlePreviousTopic}>Previous</button>
          )}
          <button className='btn btn-primary text-white' onClick={handleNextTopic}>Next</button>

        </div>
        <button className='btn btn-outline-primary text-primary mt-5' onClick={handleRecomputeDashboardData}>Refresh Dashboard</button>
      </div>
    </div>
  );
};

export default SurveyPage;
