import React, { useState } from 'react';
import { FaArrowRight } from 'react-icons/fa';

// css
import '../../styles/NestedIntegerInput.css';

const IntegerInput = ({ question, value, onChange, is_child }) => {

    // Helper function to validate input (only numeric and within range)
    const validateInput = (value, question) => {
        // null is set of infinite bounds
        let min = question.range.min !== null ? question.range.min : Number.MIN_SAFE_INTEGER;
        let max = question.range.max !== null ? question.range.max : Number.MAX_SAFE_INTEGER;
        if (value === "") {
            return ""; // To ensure that the user can delete the input
        }
        else if (/^\d+$/.test(value)) {
            const numValue = parseInt(value, 10);
            if (!isNaN(numValue) && numValue >= min && numValue <= max) {
                return numValue;
            }
        }
        return null; // Invalid input
    };

    // Handle the main input change
    const handleInputChange = (e, question) => {
        // change main value
        const validated_input = validateInput(e.target.value, question);
        if (validated_input !== null) {
            onChange(validated_input, question.id);
        }
    };

    return (
        <div className={`w-100 select-mobile ${!is_child ? ' mt-5 pb-3' : 'mt-3 py-1'}`}>
            <div className='text-start col-4'>
                <label className={`form-label m-0 ${!is_child ? 'fw-bold' : ''}`}>
                    {is_child && <FaArrowRight className='text-primary me-2' />}
                    {question.question}
                </label>
                <p className=' text-secondary'>{question.description}</p>
            </div>
            <div className="d-flex col-8 px-3" style={{ height: 'fit-content' }}>
                <input
                    className="form-control nested-integer-input"
                    value={value}
                    onChange={(e) => handleInputChange(e, question)}
                    placeholder="Enter value"
                />
            </div>
        </div>
    );
};

export default IntegerInput;
