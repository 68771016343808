import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';

// CSS
import '../../styles/Tooltip.css';


const LineChart = ({ data, yRange = null, chart_config }) => {
    const svgRef = useRef();
    const [config, setConfig] = useState(chart_config);

    useEffect(() => {
        const svg = d3.select(svgRef.current);
        const width = config.container.width;
        const height = config.container.height;
        const margin = config.container.margin;
        const availableWidth = width - margin.left - margin.right;

        // Add lines for Hires, Resignations, and Terminations
        const linesData = Object.keys(data.yValues).map((key, i) => {
            return {
                label: data.yValues[key].label,
                data: data.yValues[key].data,
                color: data.yValues[key].color
            };
        });
        const labels = data.xValues;

        // Create an object to store flags for visibility state of each line
        const visibility = Object.fromEntries(linesData.map(line => [line.label, true]));

        let tooltipContent = { data: {}, xLabel: null };
        for (let i = 0; i < linesData.length; i++) {
            tooltipContent.data[linesData[i].label] = {
                value: null,
                color: linesData[i].color
            }
        }

        // Clear previous elements
        svg.selectAll('*').remove();

        // first we need dynamically find the height of the legend that we want to add at the bottom
        // since we don't know how many lines we will have, we need to calculate the height of the legend first
        // so that when we create the parent svg, we know how much space to allocate for the legend at the bottom
        let x = 0;
        // neededHeight is the height of the legend
        let neededHeight = 0;
        linesData.forEach((line, i) => {
            // Create temporary text to measure its width
            const tempText = svg.append('text')
                .attr('font-size', config.legend.fontSize)
                .text(line.label)
                .node();

            const labelWidth = tempText.getComputedTextLength();
            const itemWidth = labelWidth + 20; // Add space for the line (20px for the line + padding)

            tempText.remove(); // Remove the temporary text element

            if (x + itemWidth > availableWidth) {
                x = 0; // Reset to the beginning of the next line
                neededHeight += config.legend.lineHeight; // Move down by lineHeight
            }
            x += itemWidth + 25; // 
        });

        // Set up the SVG canvas dimensions
        svg.attr('width', "100%")
            .attr('height', "100%")
            .attr('viewBox', `0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom + neededHeight + config.legend.marginTop}`)
            .attr('preserveAspectRatio', 'xMidYMid meet')
            .style('overflow', 'visible');

        // Set up scales
        const xScale = d3.scalePoint()
            .domain(labels)
            .range([0, width]);

        const yScale = d3.scaleLinear()
            .domain(yRange ? yRange : [0, d3.max(linesData, line => d3.max(line.data))])
            .range([height, 0]);

        // Create axes
        const xAxis = d3.axisBottom(xScale);

        xAxis.tickValues(xScale.domain().filter((d, i) => !(i % 2))); // Adjust tick frequency as needed

        const xAxisGroup = svg.append('g')
            .attr('transform', `translate(${margin.left},${height + margin.top})`)
            .style('font-size', config.xAxis.fontSize)
            .call(xAxis);

        xAxisGroup.selectAll('.tick line')
            .style('stroke-width', config.xAxis.strokeWidth)
            .attr('stroke-linecap', 'round')
            .style('stroke', config.xAxis.strokeColor); // Customize as needed

        xAxisGroup.select('.domain')
            .style('stroke-width', config.xAxis.strokeWidth);

        // Style the text labels if needed
        xAxisGroup.selectAll('.tick text')
            .style('fill', config.xAxis.fontColor); // Customize as needed

        const yAxis = d3.axisLeft(yScale)
            .ticks(config.yAxis.ticks); // Adjust tick count as necessary

        const yAxisGroup = svg.append('g')
            .attr('transform', `translate(${margin.left},${margin.top})`)
            .style('font-size', config.yAxis.fontSize)
            .call(yAxis);
        // Remove the axis line
        yAxisGroup.select('.domain').remove();

        // Optionally, remove the tick lines if you only want the numeric values
        yAxisGroup.selectAll('.tick line').remove();

        // Style the text labels if needed
        yAxisGroup.selectAll('.tick text')
            .style('fill', config.yAxis.fontColor); // Customize as needed

        // Line generator function
        const lineGenerator = d3.line()
            .x((d, i) => xScale(labels[i]))
            .y(d => yScale(d))
            .curve(d3.curveMonotoneX); // smooth line

        // ####### LEGEND #######
        const legend = svg.append('g')
            .attr('class', 'legend')
            .attr('transform', `translate(${margin.left},${height + margin.top + config.legend.marginTop})`);

        // Variables to track position and wrap to next line
        let currentX = 0;
        let currentY = 0;
        // Append each legend item
        linesData.forEach((line, i) => {
            // Create temporary text to measure its width
            const tempText = svg.append('text')
                .attr('font-size', config.legend.fontSize)
                .text(line.label)
                .node();

            const labelWidth = tempText.getComputedTextLength();
            const itemWidth = labelWidth + 20; // Add space for the line (20px for the line + padding)

            tempText.remove(); // Remove the temporary text element

            // If the current line width exceeds available space, move to the next line
            if (currentX + itemWidth > availableWidth) {
                currentX = 0; // Reset to the beginning of the next line
                currentY += config.legend.lineHeight; // Move down by lineHeight
            }

            const legendItem = legend.append('g')
                // Center the legend
                .attr('transform', `translate(${currentX}, ${currentY})`);

            // Add a small colored line representing the line
            legendItem.append('line')
                .attr('x1', 0)
                .attr('x2', 20)
                .attr('y1', 10)
                .attr('y2', 10)
                .attr('stroke', line.color)
                .attr('stroke-width', config.legend.strokeWidth)
                // Add border radius
                .attr('stroke-linecap', 'round')
                .attr('cursor', 'pointer')
                .on('click', function () {
                    // Toggle visibility state for this item
                    visibility[line.label] = !visibility[line.label];
                    // Select the corresponding line and toggle its visibility
                    svg.selectAll(`.line_${i}`).style('display', visibility[line.label] ? null : 'none');
                    svg.selectAll(`.circle-${i}`).style('display', visibility[line.label] ? null : 'none');
                    // Toggle the text decoration for the legend item
                    legendItem.select('text').style('text-decoration', visibility[line.label] ? 'none' : 'line-through');
                });


            // Add text next to the line
            legendItem.append('text')
                .attr('x', 25)
                .attr('y', 15)
                .attr('font-size', config.legend.fontSize)
                .attr('fill', config.legend.fontColor)
                .text(line.label)
                .attr('cursor', 'pointer')
                .on('click', function () {
                    // Toggle visibility state for this item
                    visibility[line.label] = !visibility[line.label];
                    // Select the corresponding line and toggle its visibility
                    svg.selectAll(`.line_${i}`).style('display', visibility[line.label] ? null : 'none');
                    svg.selectAll(`.circle-${i}`).style('display', visibility[line.label] ? null : 'none');
                    // Select the corresponding text and toggle its font-weight
                    d3.select(this).style('text-decoration', visibility[line.label] ? 'none' : 'line-through');
                });

            currentX += itemWidth + 25; // 
        });

        // ####### TOOLTIP #######
        // Create tooltip
        const tooltip = d3.select('body').append('div')
            .style('position', 'absolute')
            .style('display', 'none')
            .style('background-color', config.tooltip.backgroundColor)
            .style('padding', '8px')
            .style('border-radius', config.tooltip.borderRadius)
            .style('pointer-events', 'none')
            .style('color', config.tooltip.fontColor)
            .style('text-align', 'left');

        // ####### GRID LINES #######
        // Add grid lines
        const gridLines = svg.append('g')
            .attr('class', 'grid')
            .attr('transform', `translate(${margin.left},${margin.top})`)
            .call(
                d3.axisLeft(yScale)
                    .tickSize(-width) // Make the grid lines span the chart width
                    .tickFormat('') // Remove the text from the grid lines
                    .tickValues(yScale.ticks(config.gridlines.ticks)) // Set the number of grid lines
            )
            .style('opacity', 0.1) // Reduce the opacity of the grid lines
            .style('stroke-width', '1'); // Add a dash pattern to the grid lines

        // ####### HORIZONTAL LINE AT Y=0 ########
        // Add horizontal line to the origin for data that goes lower that 0 on the y axis
        if (yRange) {
            if (yRange[0] < 0) {
                const xOriginLine = svg.append('line')
                    .attr('stroke', '#adb5bd')
                    .attr('stroke-width', 3)
                    .attr('y1', yScale(0) + margin.top)
                    .attr('y2', yScale(0) + margin.top)
                    .attr('x1', margin.left) // Initial position (outside the view)
                    .attr('x2', width + margin.left) // Initial position (outside the view)
            }
        }

        // ####### INTERACTIVE VERTICAL LINE #######
        // Create vertical line that will follow the cursor
        const verticalLine = svg.append('line')
            .attr('stroke', '#adb5bd')
            .attr('stroke-width', 1)
            .attr('y1', margin.top)
            .attr('y2', height + margin.top)
            .attr('x1', 0) // Initial position (outside the view)
            .attr('x2', 0) // Initial position (outside the view)
            .style('opacity', 0); // Hidden by default

        // ####### DRAW LINES AND CIRCLES #######
        linesData.forEach((line, i) => {
            // Append the lines
            const path = svg.append('path')
                .datum(line.data)
                .attr('class', `line_${i}`)
                .attr('fill', 'none')
                .attr('stroke', line.color)
                .attr('stroke-width', config.lines.strokeWidth)
                .attr('transform', `translate(${margin.left},${margin.top})`)
                .attr('d', lineGenerator);

            const totalLength = path.node().getTotalLength();

            // Set up the initial stroke-dasharray and stroke-dashoffset for the drawing effect
            path
                .attr('stroke-dasharray', totalLength + ' ' + totalLength)
                .attr('stroke-dashoffset', totalLength)
                .transition() // Start the transition
                .duration(config.lines.animation.duration) // Adjust duration for animation speed
                .ease(d3.easePoly) // Linear easing for smooth drawing
                .attr('stroke-dashoffset', 0); // Animate to 0 to draw the line

            // Append circles for interactive tooltips
            const circles = svg.selectAll(`.circle-${i}`)
                .data(line.data)
                .enter()
                .append('circle')
                .attr('class', `circle-${i}`)
                .attr('data-label', line.label)
                .attr('data-xLabel', (d, i) => labels[i]) // Store the month for the tooltip
                .attr('cx', (d, i) => xScale(labels[i]) + margin.left)
                .attr('cy', d => yScale(d) + margin.top)
                .attr('r', config.circles.radius)
                .attr('fill', config.circles.fill)
                .style('stroke', line.color)
                .style('stroke-width', config.circles.strokeWidth)
            // Apply clip-path only to the first circle

            // Add text to the circles
            var circle_values = svg.selectAll(`text-${i}`)
                .data(line.data)
                .enter()
                .append('text')
                .attr('x', (d, i) => xScale(labels[i]) + margin.left)
                .attr('y', d => yScale(d) + margin.top - 15)
                .text(d => d)
                .attr('text-anchor', 'middle')
                .style('fill', line.color)
                .style('font-size', '1rem')
                .style('font-weight', 'lighter')
                .style('font-family', 'Arial')
                .style('pointer-events', 'none')
                .style('display', 'none');

            // When the user hovers of the path we want to show the values of each circle
            path
                .on('mouseover', function (event, d) {
                    d3.select(this).attr('stroke-width', config.lines.strokeWidth_hover); // Increase line width on hover
                    circle_values.style('display', 'block');
                })
                .on('mouseout', function (event, d) {
                    d3.select(this).attr('stroke-width', config.lines.strokeWidth); // Reset line width on mouseout
                    circle_values.style('display', 'none');
                });
        });

        // ####### TOOLTIP FUNCTIONALITY #######
        // get all circles
        const circles = svg.selectAll('circle');
        // Add mousemove event to move the vertical line with cursor
        svg.on('mousemove', function (event) {
            const [mouseX, mouseY] = d3.pointer(event); // Get the mouse position relative to the SVG
            if (mouseX >= margin.left && mouseX <= width + margin.left && mouseY >= margin.top && mouseY <= height + margin.top) {
                verticalLine
                    .attr('x1', mouseX)
                    .attr('x2', mouseX)
                    .style('opacity', 1); // Make the line visible

                // The counter is used to check if any of the circles are near the vertical line
                // if the counter remains 0, we hide the tooltip
                let circlesTouched = 0;
                // Check proximity to each circle
                circles.each(function (d, i) {
                    const cx = Number(d3.select(this).attr('cx')); // X coordinate of each circle
                    if (Math.abs(mouseX - cx) < 10) { // Adjust proximity threshold
                        circlesTouched++;
                        d3.select(this).attr('r', config.circles.radius_hover); // Simulate hover effect
                        const circleLabel = d3.select(this).attr('data-label'); // Assuming each circle has a label stored in `data-label`
                        const circlexLabel = d3.select(this).attr('data-xLabel'); // Get the value of the x label of the circle
                        const circleValue = d; // d is the value of the circle
                        // we update the tooltipContent object with the value of the circle
                        tooltipContent.data[circleLabel] = {
                            ...tooltipContent.data[circleLabel],
                            value: circleValue
                        }
                        // Add the month to the tooltip content
                        tooltipContent['xLabel'] = {
                            value: circlexLabel,
                        }
                    } else {
                        d3.select(this).attr('r', config.circles.radius); // Reset the circle radius if not near (hover out)
                    }
                });

                // If any circle is being touched, show the tooltip
                if (circlesTouched > 0) {
                    // Show the tooltip with collected content
                    tooltip.style('display', 'block')
                        .html(() => {
                            const xLabelText = `${tooltipContent.xLabel.value}`;
                            const dataLabelsText = Object.keys(tooltipContent.data).map(key => {
                                return `
                                <div>
                                    <span class="circle" style="background-color: ${tooltipContent.data[key].color}; display: inline-block; width: 15px; height: 15px; border-radius: 50%; margin-right: 6px;"></span>
                                    <b>${key}:</b> ${tooltipContent.data[key].value}
                                </div>`;
                            }).join('')
                            return xLabelText + dataLabelsText;
                        })
                        .style('top', `${event.pageY - 10}px`)
                        .style('left', `${event.pageX + 16}px`);
                } else {
                    // Hide the tooltip if no circles are being touched
                    tooltip.style('display', 'none');
                }

            } else {
                verticalLine.style('opacity', 0); // Hide the line if outside the chart
                tooltip.style('display', 'none'); // Hide the tooltip
                circles.attr('r', config.circles.radius); // Reset circle sizes
            }
        });

        // Cleanup the tooltip when component is unmounted
        return () => {
            tooltip.remove();
        };
    }, [data]);

    return (
        <div>
            <svg ref={svgRef}></svg>
        </div>
    );
};

export default LineChart;
